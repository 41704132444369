.clear {
  clear: both;
}

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

ul {
  list-style: none;
  margin-bottom: 0px;
  padding: 0;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

.anticon {
  vertical-align: 0;
}

.navbar {
  position: relative;
  padding: 0 !important;
}

.navbar-top {
  width: 100%;
  background-color: #eaeaea;
  position: relative;
  height: 91px;
}

.navbar-top-left {
  padding: 35px 0;
}

.navbar-top-left li {
  float: left;
  font-size: 14px;
  color: #0e143d;
  margin-right: 30px;
}

.mobile-right-top {
  position: absolute;
  right: 20px;
  top: 0;
  padding: 30px 0;
}

.mobile-favor-inbox {
  display: none;
  position: relative;
}

.mobile-right-top .my-favour {
  padding-right: 10px;
}

.navbar-toggler span {
  background: url(images/menu-icon.png) right center no-repeat;
  padding-right: 40px;
}

@media(max-width : 400px) {
  .lang {
    padding: 0;
  }
}

.lang {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paint>img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.themepopout {
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 2 columns */
  gap: 10px;
  margin-top: 20px;
}

.themepopout> :nth-last-child(-n+1) {
  grid-column: span 2;
}

.themepopout>div>img {
  width: 100%;
}

.theme-label {
  background-color: lightcyan;
  border-radius: 10px;
  border: 1px solid lightgray;
  font-weight: bold;
}

.theme-label>h2 {
  font-size: 1.5em;
}


.singletheme>div {
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.singletheme {
  border: 1px solid white;
  cursor: pointer;
}

.lang-current {
  /* background: url(images/lang-arrow.png) right center no-repeat; */
  /* padding-right: 24px; */
  line-height: 90px;
  position: relative;
}

/* .lang .lang-dropdown {
    padding: 0;
    width: 117px;
    text-align: center;
    color: #FFF;
    text-transform: uppercase;
    font-size: 16px;
    position: absolute;
    top: 60px;
    left: -30px;
    background-color: #21265d;
    background-image: linear-gradient(to right, #1eab85 , #4bbe67);
    font-weight: 300;
    display: none;
}

.lang .lang-current:hover > .lang-dropdown{
    display: block;
}


.lang .lang-dropdown li{
  border-top: 1px solid #97dda9;
} */

.mobile-menu {
  position: absolute;
  z-index: 999;
  min-width: 220px;
  top: 91px;
  right: 0;
}

.navbar-bottom {
  padding: 15px 0;
}

.logo {
  float: left;
  padding-left: 15px;
  width: 397px;
}

.logo a {
  display: block;
}

.logo img {
  width: 100%;
}

.navbar-bottom-link {
  float: right;
}

.logo-100-yr {
  height: 45px;
  width: auto;
  cursor: pointer
}

.navbar-bottom-link ul li {
  float: left;
  font-weight: 400;
  padding: 0 10px;
  line-height: 36px;
  font-size: 14px;
  letter-spacing: 1px;
  border-left: 1px solid #0e143d;
}

.navbar-bottom-link ul li a {
  color: #0e143d;
}

.navbar-nav .logout {
  border-top: 1px solid #6e82c3;
  margin-top: 15px;
}

.mobile-only {
  display: none;
}

.search input {
  width: 140px;
  /* width: 220px; */
  padding: 5px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  line-height: 24px;
  z-index: 1;
  /* background: url(images/icon-search.png) 90% center no-repeat; */
}

.search input::placeholder {
  color: #aaa !important;
}

.search input::-moz-placeholder {
  color: #aaa !important;
}

.search input:-ms-input-placeholder {
  color: #aaa !important;
}

.search input:-moz-placeholder {
  color: #aaa !important;
}

input:focus {
  outline: 0;
}

button {
  cursor: pointer;
}

.my-inbox {
  background: url(images/icon-inbox.png) left center no-repeat;
  padding-left: 25px;
}

.my-download {
  background: url(images/main-icon-download.png) left center no-repeat;
  padding-left: 25px;
}

.my-favour {
  background: url(images/icon-like.png) left center no-repeat;
  padding-left: 25px;
}

.admin-console {
  background: url(images/icon-admin.png) left center no-repeat;
  padding-left: 25px;
}

.hero-banner {
  margin-bottom: 40px;
}

.home-section {
  margin-bottom: 60px;
}


/*1196 - 992*/
@media (max-width: 1196px) and (min-width: 992px) {
  .header-with-icon h2 {
    display: inline-block;
    /* ensures the h2 behaves like a block-level element */
    max-width: 300px;
    /* Adjust this to your desired width */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line */
    overflow: hidden;
    /* Hides any content that exceeds the container */
    text-overflow: ellipsis;
  }
}



.btn-show-all {
  display: block;
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 70px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
}

.header-with-icon {
  position: relative;
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
  height: 64px;
  font-weight: 400;
}

.header-without-icon h2 {
  /*background: url(images/header-bg.png) right center no-repeat;*/
  height: 46px;
  line-height: 46px;
  font-size: 21px;
  color: #FFF;
  width: calc(100% - 210px);
  margin-bottom: 0;
  float: left;
  font-weight: 300;

}

row-new {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.clearfix-new::before,
.clearfix-new::after {
  content: "";
  display: table;
}

.clearfix-new::after {
  clear: both;
}

.clearfix-new {
  zoom: 1;
}

.media-corner-new {
  background: url(images/icon-wisdom-gallery.png) 25px center no-repeat;
  padding-left: 75px;
}

.social-media-container {
  display: flex;
  justify-content: left;
  gap: 15px;
  width: 100%;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .social-media-container {
    display: block;
  }
}

/* Facebook, Instagram, and YouTube components */
.latest-news-fb,
.latest-news-ig,
.latest-news-yt {
  flex: 1;
  /*max-width: 33.33%;*/
}

.header-without-icon-new {
  height: 47px;
  font-weight: 400;
  border-bottom: 2px solid #fa6900;
  margin-bottom: 40px;
}

.header-without-icon-new h2 {
  background: url(images/header-bg.png) right center no-repeat;
  height: 46px;
  line-height: 46px;
  font-size: 21px;
  color: #FFF;
  width: calc(100% - 210px);
  margin-bottom: 0;
  float: left;
  font-weight: 300;
}

.header-without-icon .media-corner {
  background: url(images/icon-wisdom-gallery.png) 25px center no-repeat;
  padding-left: 75px;
}


.default-date {
  border: 1px solid #fa6900;
  color: #fa6900;
}

.default-mini {
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
}

.default-header h2 {
  background: url(images/header-bg.png) right center no-repeat;
  background-size: cover;

}

.default-mini h2 {
  color: #FFF;
}


.night-mini {
  background-color: #0F41A3;
}

.night-mini a h2 {
  color: white;
}

.lightblue-mini {
  background-color: #9AC5F4;
}

.lightblue-mini a h2 {
  color: black;
}

.lightgreen-mini {
  background-color: #86A789;
}

.lightgreen-mini a h2 {
  color: #EBF3E8;
}

.lightpink-mini {
  background-color: #FFD1DA;
}

.lightpink-mini a h2 {
  color: #433878;
}

/*--------night------------*/

.night-full-width-header {
  background-color: #0F41A3;

}

.night-header h2 {
  background: url(images/header-bg_1.png) right center no-repeat;
  background-size: cover;
}

.night-home-section {
  background-color: #FFFFE0;
}

.night {
  background-color: #0F41A3;
  background-image: none;
  color: white;
}


.night-detail-bg-color {
  background-color: #faf8ff;
}

.night h2 {
  color: white;
}

.night-date {
  color: black;
  border: 1px solid black;
}

.night-font {
  color: #000000;
}

.default-show-all {
  background-color: #f89a0b;
  background-image: linear-gradient(to bottom, #f89a0b, #d87101);

}

.night-show-all {
  background-color: #f89a0b;
  background-image: linear-gradient(to bottom, #f89a0b, #d87101);
}

/*--------lightblue------------*/
.lightblue-full-width-header {
  background-color: #9AC5F4;
}

.lightblue-full-width-header>div>h2 {
  color: black;
}

.lightblue-header h2 {
  background: url(images/header-bg_2.png) right center no-repeat;
  background-size: cover;
}

.lightblue-home-section {
  background-color: #A7ECEE;
}

.lightblue {
  background-color: #9AC5F4;
  background-image: none;
}

.lightblue h2 {
  color: black;
}

.lightblue-detail-bg-color {
  background-color: #E8E8E8;
}

.lightblue-font {
  color: #000000;
}


.lightblue-show-all {
  background-color: #131D6F;
  background-image: linear-gradient(to bottom, #131D6F, #131D6F);
}

.lightblue-date {
  color: #131D6F;
  border: 1px solid #131D6F;
}

/*--------lightpink------------*/
.lightpink-header h2 {
  background: url(images/header-bg_4.png) right center no-repeat;
  background-size: cover;
}

.lightpink-full-width-header {
  background-color: #FFD1DA;
}

.lightpink-full-width-header>div>h2 {
  color: #433878;
}


.lightpink-home-section {
  background-color: #FFF7FA;
}

.lightpink {
  background-color: #FFD1DA;
  background-image: none;
}

.lightpink h2 {
  color: #433878;
}

.lightpink-detail-bg-color {
  background-color: #E8E8E8;
}

.lightpink-font {
  color: #7E60BF;
}

.lightpink-show-all {
  background-color: #6420AA;
  background-image: linear-gradient(to bottom, #6420AA, #6420AA);
}

.lightpink-date {
  border: 1px solid #7E60BF;
  color: #7E60BF;
}

/*--------lightgreen------------*/
.lightgreen-header h2 {
  /*border: 1px solid black;*/
  background: url(images/header-bg_3.png) right center no-repeat;
  background-size: cover;
}

.lightgreen-full-width-header {
  background-color: #86A789;
}

.lightgreen-full-width-header>div>h2 {
  color: #EBF3E8;
}

.lightgreen-header {
  /*background: url(images/header-bg_3.png) right center no-repeat;*/
}

.lightgreen-home-section {
  background-color: #D2E3C8;
}

.lightgreen {
  background-color: #86A789;
  background-image: none;
}

.lightgreen h2 {
  color: white;
}

.lightgreen-detail-bg-color {
  background-color: #E8E8E8;
}

.lightgreen-font {
  color: #161D6F;
}

.lightgreen-show-all {
  background-color: #006769;
  /*background-image: linear-gradient(to bottom, #6420AA, #6420AA);*/
}

.lightgreen-date {
  border: 1px solid #0B2F9F;
  color: #0B2F9F;
}

/*theme end*/

.header-with-icon .header-icon {
  margin-top: 10px;
  height: 60px;
  position: absolute;
  left: 20px;
  top: -8px;
}

.header-with-icon h2 {
  /*color: #FFF;*/
  padding-left: 90px;
  font-size: 18px;
  line-height: 64px;
  width: calc(100% - 130px);
  float: left;
  font-weight: 300;
}

.default-header-with-icon {
  color: white
}

.btn-show-all:hover {
  color: #FFF;
}

.header-with-icon .btn-show-all {
  float: right;
  margin-top: 15px;
  margin-right: 20px;
}

.latest-news-list {
  width: 100%;
}

.latest-news-list li {
  padding: 25px 0;
  height: 70px;
  display: table;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.latest-news-list .date {
  background-color: #FFF;
  font-size: 15px;
  line-height: 15px;
  height: 35px;
  padding: 8px 0;
  width: 120px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  /* display: table-cell; */
  display: inline-table;
  vertical-align: middle;
  margin-bottom: 0;
}

.latest-news-list .title {
  font-size: 15px;
  line-height: 20px;
  width: calc(100% - 150px);
  color: #333;
  /* display: table-cell; */
  display: inline-table;
  vertical-align: middle;
  margin-bottom: 0;
  padding-left: 20px;
}

.mini-blog-list {
  width: 100%;
  padding-top: 20px;
}

.mini-blog-list li {
  padding: 15px 0;
  height: 40px;
  display: table;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 15px;
}


.mini-blog-list .date {
  font-size: 15px;
  line-height: 15px;
  width: 100px;
  text-align: center;
  font-weight: 300;
  color: #333;
  display: table-cell;
  vertical-align: middle;
  margin-bottom: 0;
}

.mini-blog-list .date span {
  display: block;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
}

.mini-blog-list .title {
  font-size: 15px;
  line-height: 20px;
  width: calc(100% - 150px);
  color: #333;
  display: table-cell;
  vertical-align: middle;
  margin-bottom: 0;
  padding-left: 10px;
}

.header-without-icon {
  height: 47px;
  font-weight: 400;
  border-bottom: 2px solid #fa6900;
  margin-bottom: 40px;
}


.header-without-icon .k-market {
  background: url(images/icon-k-market.png) 20px center no-repeat;
  padding-left: 75px;
}

.header-without-icon .k-square {
  background: url(images/icon-k-square.png) 20px center no-repeat;
  padding-left: 75px;
}

.header-without-icon .wisdom-gallery {
  background: url(images/icon-wisdom-gallery.png) 25px center no-repeat;
  padding-left: 75px;
}

.header-without-icon .hot-links {
  background: url(images/icon-hot-links.png) 25px center no-repeat;
  padding-left: 75px;
}

.hot-link-href-admin {
  display: none;
}

.hot-link-href {
  color: #0077CC;
}

.header-without-icon .btn-show-all {
  float: right;
  margin-top: 3px;
  margin-right: 50px;
}

.media-box {
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  padding: 20px 0;
}

.media-thumb {
  border: 3px solid #071a5d;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.media-thumb a {
  display: block;
}

.media-thumb img {
  width: 100%;
}

.media-thumb span {
  width: 110px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #071a5d;
  color: #FFF;
  margin-bottom: 0;
}

.media-box p {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.media-box a {
  display: block;
}

.full-width-header {
  /*background-color: #1eab85;*/
  /*background-image: linear-gradient(to right, #1eab85, #4bbe67);*/
  height: 64px;
  font-weight: 400;
}

.default-full-width {
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);

}

.full-width-header .container {
  position: relative;
}

.full-width-header .header-icon {
  position: absolute;
  left: 20px;
  top: -8px;
}

.full-width-header h2 {
  color: #FFF;
  padding-left: 100px;
  font-size: 22px;
  line-height: 64px;
  width: calc(100% - 130px);
  float: left;
  font-weight: 300;
}

.full-width-header .btn-show-all {
  float: right;
  margin-top: 15px;
  margin-right: 30px;
}

.self-learning-type {
  margin-bottom: 40px;
}

.self-learning-box {
  border: 3px solid #071a5d;
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
}

.self-learning-box a {
  display: block;
}

.self-learning-box img {
  width: 100%;
}

.self-learning-box span {
  min-width: 120px;
  height: 36px;
  line-height: 34px;
  padding: 0 20px;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #071a5d;
  color: #FFF;
  margin-bottom: 0;
}

.weekly-topic {
  width: 100%;
  max-width: 1270px;
  margin: 0 auto 60px;
  position: relative;
}

.weekly-topic img {
  width: 100%;
}


/* start for kc */
.weekly-topic-words {
  position: absolute;
  left: 40px;
  top: calc(50% - 45px);
}

.knowledge-cocktail-words {
  position: absolute;
  left: 40px;
  top: calc(50% - 45px);
}

.weekly-topic-words h2 {
  font-size: 54px;
  line-height: 60px;
  font-weight: 600;
  text-transform: uppercase;
  color: #FFF;
  margin-bottom: 10px;
}

.knowledge-cocktail-words h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  color: #FFF;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.weekly-topic-words p {
  font-size: 16px;
  font-weight: 600;
  color: #FFF;
  margin-bottom: 0;
}

/* end for kc */
.page-content {
  padding: 30px 0 60px 0;
}

.default-content {
  background-color: #f5f8f9;
}

.nav-box {
  width: calc(50% - 20px);
  margin: 0 10px 20px;
  background-color: #FFF;
  box-shadow: 0 2px 10px #ccc;
  padding: 30px;
  height: 110px;
  display: table;
  float: left;
}

.nav-box a {
  display: table-cell;
  vertical-align: middle;
  font-size: 20px;
  line-height: 24px;
  color: #555;
  font-weight: 300;
  padding-right: 30px;
  background: url(images/nav-icon.png) right center no-repeat;
}

.page-resources {
  padding-bottom: 20px;
}

.page-resources h1 {
  font-size: 25px;
  padding-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: url(images/header-line.png) bottom left no-repeat;
  margin-bottom: 30px;
}

.header-of-month {
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 40px;
  padding-left: 42px;
  margin-bottom: 30px;
  background: url(images/icon-date.png) 10px center no-repeat #20ac83;
}

.resources-list {
  margin: 0 0 40px;
}

.resources-box {
  width: 100%;
  height: 108px;
  /* margin: 0 0 20px 0; */
  padding: 10px;
  background-color: #FFF;
  box-shadow: 1px 1px 1px #ccc;
  position: relative;
}

.resources-box .left {
  width: 110px;
  height: 100%;
  float: left;
  background-color: #4376cb;
}

/* .resources-box .left img{ */
.resources-box .left div {
  display: block;
  vertical-align: middle;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.resources-box .left .document {
  width: 50px;
  height: 70px;
  margin-top: 9px;
}

.resources-box .left .photo {
  width: 65px;
  height: 50px;
  margin-top: 20px;
}

.resources-box .left .video {
  width: 55px;
  height: 56px;
  margin-top: 17px;
}

.resources-box .right {
  width: calc(100% - 220px);
  margin-right: 100px;
  padding: 5px 0 0 15px;
  height: 100%;
  float: left;
  background-color: #FFF;
}

.resources-box p {
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  color: #222;
  margin-bottom: 10px;
}

.resources-box .title {
  height: 28px;
}

.resources-box a,
.resources-box a:hover {
  display: block;
}

.resources-box .right a,
.resources-box .right a:hover {
  font-size: 12px;
  font-weight: 400;
  color: #007eff !important;
  padding-top: 2px;
  padding-left: 17px;
  text-decoration: underline !important;
  cursor: pointer;
}

.resources-box .download {
  background: url(images/icon-download.png) left center no-repeat;
}

.resources-box .view {
  background: url(images/icon-view.png) left center no-repeat;
}

.resources-box .icon-download {
  position: absolute;
  top: 36px;
  right: 130px;
}

.resources-box .icon-popup-admin {
  position: absolute;
  top: 36px;
  right: 75px;
}

.resources-box .icon-my-favourites {
  position: absolute;
  top: 36px;
  right: 30px;
  cursor: pointer;
}

.resources-box a {
  display: block;
}

.resources-box-more .btn-show-all {
  margin: 0 auto;
}

.page-content .btn-more {
  display: block;
  background-color: #f89a0b;
  background-image: linear-gradient(to bottom, #f89a0b, #d87101);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 100px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 0 auto 20px;
}

.resources-area {
  width: 100%;
  max-width: 1000px;
}

.resources-share-inbox {
  margin-left: 4px;
  cursor: pointer;
}

.page-resources-top {
  position: relative;
  padding-right: 150px;
  color: #071a5d;
  font-weight: 500;
}

.page-content .page-resources-top h1 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.page-resources-top p {
  font-size: 15px;
}

.page-resources-top .btn-download {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 140px;
  height: 40px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 0 auto 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.document-iframe {
  width: 100%;
  height: 100%;
  min-height: 600px;
  margin-bottom: 60px;
}

.resources-content {
  margin-bottom: 40px;
}

.resources-content p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 30px;
  color: #333;
  font-weight: 300;
}

.related-resources {
  width: 100%;
}

.related-resources {
  border: 1px solid #ccc;
}

.relatedh2 {
  color: #FFF;
  font-size: 15px;
  /*background-image: linear-gradient(to right, #1eab85, #4bbe67);*/
  height: 44px;
  padding: 0 20px;
  margin-bottom: 0;
}

.default-related-h2 {
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
}

.night-related-h2 {
  background-color: #0F41A3;
}

.lightblue-related-h2 {
  background-color: #9AC5F4;
}

.lightgreen-related-h2 {
  background-color: #86A789;
}

.lightpink-related-h2 {
  background-color: #FFD1DA;
}

.related-resources h2 span {
  display: block;
  padding-left: 30px;
  line-height: 44px;
  background: url(images/icon-related-resources.png) left center no-repeat;
}

.related-list {
  padding: 30px 0;
  margin: 0;
}

.related-resources a {
  display: block;
}

.related-resources img {
  display: block;
  width: 100%;
  max-width: 200px;
  max-height: 150px;
  border: 1px solid #000;
  margin: 0 auto;
}

.related-resources .title {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  font-size: 15px;
  color: #467a62;
  text-decoration: underline;
  padding-top: 10px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 40px;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%;
height: 100%; */
}


.my-inbox-header,
.my-download-header,
.my-favourites-header {
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
  height: 64px;
  font-weight: 400;
}

.my-inbox-header .header-icon {
  position: absolute;
  left: 20px;
  top: -8px;
}

.my-inbox-header h2,
.my-download-header h2,
.my-favourites-header h2 {
  color: #FFF;
  font-size: 22px;
  line-height: 64px;
  width: calc(100% - 310px);
  float: left;
  font-weight: 300;
  margin-bottom: 0;
}

.my-inbox-header h2 {
  padding-left: 45px;
  background: url(images/my-inbox.png) left center no-repeat;
}

.my-download-header h2 {
  padding-left: 45px;
  /* background: url(images/main-icon-download.png) left center no-repeat; */
}

.my-favourites-header h2 {
  padding-left: 40px;
  background: url(images/my-favourites.png) left center no-repeat;
}

.page-control {
  position: relative;
  float: right;
  width: 300px;
  height: 64px;
}

.page-control p {
  line-height: 64px;
  color: #FFF;
  font-weight: 300;
  font-size: 13px;
}

.my-inbox-prev {
  position: absolute;
  top: 5px;
  right: 120px;
}

.my-inbox-next {
  position: absolute;
  top: 5px;
  right: 40px;
}

.my-inbox-top {
  padding: 10px 0;
}

.my-inbox-top li {
  float: left;
  border-right: 1px solid #333;
  padding: 0 40px;
  line-height: 16px;
}

.my-inbox-top .sent-by,
.my-inbox-top .date-sent,
.my-inbox-top .delete {
  width: 16.6%;
  padding: 0 20px;
  text-align: center;
}

.my-inbox-top .title {
  width: 50%;
  padding: 0 40px;
}

.my-inbox-top .delete {
  border-right: none;
}

.my-inbox-top .sent-by span {
  display: inline-block;
  padding: 20px 0 20px 40px;
  background: url(images/icon-sent-by.png) left center no-repeat;
}

.my-inbox-top .title span {
  display: block;
  padding: 20px 0 20px 36px;
  background: url(images/icon-title.png) left center no-repeat;
}

.my-inbox-top .date-sent span {
  display: inline-block;
  padding: 20px 0 20px 40px;
  background: url(images/icon-date-sent.png) left center no-repeat;
}

.my-inbox-top .delete a {
  display: inline-block;
  padding: 20px 0 20px 40px;
  background: url(images/icon-delete.png) left center no-repeat;
}

.inbox-list {
  background-color: #FFF;
  padding: 0 10px;
}

.inbox-list .row {
  margin: 0;
  border-bottom: 1px solid #ccc;
}

.inbox-list .sent-by {
  width: 16.6%;
  height: 80px;
  display: table;
  padding: 0 10px;
}

.inbox-list .sent-by p {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  line-height: 18px;
  color: #159872;
  font-weight: 700;
}

.inbox-list .title {
  width: 50%;
  height: 80px;
  display: table;
  padding: 0 10px;
}

.inbox-list .title p {
  display: table-cell;
  vertical-align: middle;
  font-size: 15px;
  line-height: 18px;
  color: #333;
}

.inbox-list .date-sent {
  width: 16.6%;
  height: 80px;
  display: table;
  padding: 0 10px;
}

.inbox-list .date-sent p {
  display: table-cell;
  vertical-align: middle;
}

.inbox-list .date-sent p span {
  display: block;
  font-size: 15px;
  line-height: 39px;
  width: 130px;
  height: 40px;
  color: #999;
  font-weight: 300;
  font-style: italic;
  border: 1px solid #999;
  border-radius: 20px;
  margin: 0 auto;
  text-align: center;
}

.inbox-list .delete {
  width: 16.6%;
  height: 80px;
  display: table;
  padding: 0 10px;
}

.inbox-list .delete fieldset {
  margin: 32px auto 0;
}

.inbox-list .delete input[type=checkbox] {
  display: block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 auto;
}


.mini-blog-header,
.media-n-resources-header {
  height: 64px;
  font-weight: 400;
}

.mini-blog-header .header-icon,
.media-n-resources-header .header-icon {
  position: absolute;
  left: 20px;
  top: -8px;
}

.mini-blog-header h2,
.media-n-resources-header h2 {
  padding-left: 45px;
  font-size: 22px;
  line-height: 64px;
  width: calc(100% - 120px);
  float: left;
  font-weight: 300;
  margin-bottom: 0;
}

.mini-blog-header h2 {
  background: url(images/mini-blog.png) left center no-repeat;
}

.media-n-resources-header h2 {
  background: url(images/icon-media.png) left center no-repeat;
}

.btn-my-blog,
.btn-new-post,
.btn-elearning {
  display: block;
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 120px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 15px 0 0;
  float: left;
}

btn-elearning:hover {
  color: white;
}

.default-blog {
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
}


.btn-elearning-search {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 120px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 0 5px;
  float: left;
}

.btn-elearning {
  margin-left: 5px;
}

.latest-blog {
  padding: 30px 0 0 0;
}

.latest-blog .row {
  margin: 0;
}

.latest-blog-circle {
  font-size: 30px;
  line-height: 36px;
  padding: 22px 0;
  text-align: center;
  color: #FFF;
  font-weight: 300;
  width: 140px;
  height: 140px;
  background-color: #255ba6;
  border-radius: 70px;
  letter-spacing: 2px;
  margin: 40px auto 0;
}

.latest-blog-item {
  height: 100%;
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
}

.latest-blog-item h3 {
  font-size: 17px;
  margin-bottom: 5px;
}

.latest-blog-box {
  background-color: #FFF;
  border-top: 4px solid #3db8f7;
  box-shadow: 0 1px 5px #ccc;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.latest-blog-box a {
  display: block;
  width: 100%;
  height: 100%;
}

.latest-blog-photo {
  width: 100%;
  height: 140px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 10px;
}

.latest-blog-photo span {
  display: inline-block;
  background-color: #071a5d;
  color: #FFF;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 10px;
}

.blog-desc {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #555;
  margin-bottom: 10px;
}

.blog-type {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  padding: 5px 20px;
  color: #555;
  margin-bottom: 10px;
  border: 1px solid #555;
  border-radius: 30px;
  font-weight: 200;
  text-align: center;
}


.latest-blog-item {
  padding-bottom: 10px;
}

.owl-theme .owl-nav {
  width: calc(33.3% - 40px);
  position: absolute;
  left: -33.3%;
  top: 185px;
}

.owl-theme .owl-dots {
  width: calc(33.3% - 40px);
  position: absolute;
  left: -33.3%;
  top: 240px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  width: 20px;
  height: 38px;
}

.owl-theme .owl-nav .owl-prev {
  margin: 5px 10px !important;
  background: url(images/latest-blog-prev.png) center no-repeat !important;
}

.owl-theme .owl-nav .owl-next {
  margin: 5px 10px !important;
  background: url(images/latest-blog-next.png) center no-repeat !important;
}

.select-blog-bar {
  position: relative;
}

.blog-type-toggler {
  display: block;
  /* background-color: #183c6e; */
  width: 100%;
  border: none;
  margin-top: 12px;
  height: 40px;
  line-height: 40px;
  color: black;
  text-align: left;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 300;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
}

.blog-type-toggler.collapsed {
  border-radius: 20px;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
}

.blog-type-toggler:focus {
  outline: 0;
}

.blog-type-toggler span {
  display: block;
  /* background: url(images/lang-arrow.png) right center no-repeat; */
}

.blog-type-collapse {
  -ms-flex-preferred-size: 100%;
  position: absolute;
  flex-basis: 100%;
  left: 15px;
  width: calc(100% - 30px);
  top: 52px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #183c6e;
  z-index: 9;
  color: #FFF;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 300;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.blog-type-nav {
  padding-bottom: 10px;
}

.blog-type-nav li {
  padding: 10px 0;
}

.blog-sort-toggler {
  display: inline-block;
  /* background-color: #FFF; */
  width: calc(100% - 80px);
  border: none;
  margin-top: 12px;
  height: 40px;
  line-height: 40px;
  /* color: #666; */
  color: black;
  text-align: left;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 300;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
  max-width: 300px;
}

.blog-sort-toggler.collapsed {
  border-radius: 20px;
}

.blog-sort-toggler:focus {
  outline: 0;
}

.blog-sort-toggler span {
  display: block;
  /* background: url(images/icon-arrow.png) right center no-repeat; */
}

.blog-sort-collapse {
  -ms-flex-preferred-size: 100%;
  position: absolute;
  flex-basis: 100%;
  right: 15px;
  width: calc(100% - 110px);
  max-width: 300px;
  top: 52px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #FFF;
  z-index: 9;
  color: #666;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 300;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: left;
  box-shadow: 0 1px 1px #ccc;
}

.blog-sort-nav {
  padding-bottom: 10px;
}

.blog-sort-nav li {
  padding: 10px 0;
}

.blog-sort-label {
  display: inline-block;
  width: 70px;
  font-size: 14px;
  font-weight: 300;
  color: #FFF;
  text-align: left;
}

.blog-sort {
  text-align: right;
}

.blog-post {
  padding: 20px;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0 0 10px #ddd;
  margin-bottom: 20px;
}

.blog-post .left {
  float: left;
  width: 268px;
}

.blog-post .left img {
  width: 100%;
}

.blog-post .right {
  float: right;
  width: calc(100% - 288px);
}

.blog-post .title {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
}

.blog-post .title span {
  font-size: 15px;
  padding-left: 5px;
}

.blog-post .desc {
  display: block;
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 10px;
}

.blog-post .bottom {
  margin-bottom: 0;
}

.blog-post .author {
  display: inline-block;
  font-size: 15px;
  color: #808080;
  line-height: 35px;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #808080;
  text-transform: uppercase;
}

.blog-post .blog-type {
  padding: 9px 20px;
  margin-bottom: 0;
}

.blog-details-header {
  height: 64px;
  font-weight: 400;
}

.default-blog-header {
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);

}

.blog-details-header .header-icon {
  position: absolute;
  left: 20px;
  top: -8px;
}

.blog-details-header h2 {
  padding-left: 45px;
  font-size: 22px;
  line-height: 64px;
  width: calc(100% - 260px);
  float: left;
  font-weight: 300;
  margin-bottom: 0;
  background: url(images/mini-blog.png) left center no-repeat;
}




.btn-post-creation {
  display: block;
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 120px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 15px 20px 0 0;
  float: left;
}

.default-btn-post {
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
}

.blog-details-main {
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 0 10px #ddd;
  padding: 25px;
  position: relative;
  margin-bottom: 40px;
}

/* .blog-details-main h1{
  font-size: 30px;
  color: #255ba6;
  margin-bottom: 5px;
} */

.blog-details-main .date {
  font-size: 15px;
  color: #aeaeae;
  font-style: italic;
  margin-bottom: 10px;
}

.blog-details-main .like {
  font-size: 15px;
  color: #2ab07d;
  margin-bottom: 15px;
}

.blog-article div {
  margin-bottom: 30px;
}

.blog-article p {
  font-size: 15px;
  line-height: 25px;
  color: #222;
  margin-bottom: 30px;
}

.blog-details-main .action-btn-bar {
  margin: 0 -25px;
  padding: 15px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.blog-details-main .action-btn-bar {
  text-align: center;
  margin-bottom: 20px;
}

.blog-details-main .action-btn {
  width: 33.33%;
  float: left;
}

.blog-details-main .action-btn-bar .middle {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.blog-details-main .action-btn a {
  display: inline-block;
  padding-left: 26px;
  font-weight: bold;
}

.blog-details-main .action-btn .reply {
  background: url(images/icon-reply.png) left center no-repeat;
  color: #255ba6;
}

.blog-details-main .action-btn .bookmark {
  background: url(images/icon-bookmark.png) left center no-repeat;
  color: #255ba6;
}

.blog-details-main .action-btn .liked {
  background: url(images/icon-like.png) left center no-repeat;
  color: #ff6900;
}

.blog-tabs a {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 5px;
  color: #255ba6;
}

.blog-tabs {
  margin-bottom: 0;
}

.blog-triangle {
  position: absolute;
  bottom: -32px;
  left: 40px;
  z-index: 9;
}

.blog-comments {
  width: 100%;
  background-color: #FFF;
  border: 1px solid #ccc;
  padding: 30px 25px 0 25px;
  position: relative;
  margin-bottom: 40px;
}

.blog-comments h3 {
  font-size: 19px;
  font-weight: 600;
  color: #888888;
  margin-bottom: 10px;
}

.blog-comments .user-icon {
  width: 56px;
  float: left;
}

.blog-comments .user-icon img {
  width: 100%;
}

.blog-comments .comments-right {
  width: calc(100% - 75px);
  float: right;
  position: relative;
  padding-right: 40px;
}

.blog-comments .comments-right p {
  font-size: 15px;
  margin-bottom: 5px;
  color: #666666;
}

.blog-comments .user-name {
  text-transform: uppercase;
  margin-right: 20px;
}

.blog-comments .comment-date {
  color: #888888;
}

.blog-comments .like-reply {
  color: #1e73b5;
  padding-top: 15px;
}

.blog-comments .like-reply a {
  color: #1e73b5;
}

.blog-comments .more-action {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: 0;
}

.blog-action-collapse {
  -ms-flex-preferred-size: 100%;
  position: absolute;
  flex-basis: 100%;
  right: 0;
  width: 100px;
  top: 30px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f8f9;
  z-index: 9;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.blog-action-collapse ul {
  padding: 5px 0;
}

.blog-comments .blog-action-collapse ul li {
  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
  border: none;
}

.blog-action-collapse ul li a:hover {
  color: #1e73b5;
}

.blog-comments-list li {
  padding: 25px 0 20px 0;
  border-bottom: 1px solid #c6e2df;
}

.sub-comments {
  padding-left: 70px;
  padding-top: 20px;
}

.blog-comments-list .sub-comments li {
  padding: 20px 0;
  border-bottom: none;
  border-top: 1px solid #c6e2df;
}

.load-more {
  margin: 0 -25px;
  padding: 25px;
  background-color: #b2d4dc;
}

.load-more a {
  display: block;
  text-align: center;
  color: #3e6372;
}

.add-comment {
  padding: 30px 0;
}

.btn-add-comment {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 160px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 0 auto;
}


.vertical-slider {
  margin-bottom: 40px;
}

.related-blog-list {
  background-color: #FFF;
  padding: 15px 20px 15px 30px;
}

.related-blog-list li {
  color: #467a62;
  list-style-type: disc;
  margin-bottom: 20px;
}

.related-blog-list a {
  color: #467a62;
  font-size: 15px;
  display: block;
}

.related-blog-list li p {
  color: #666;
  font-size: 15px;
  margin-bottom: 0;
}

.related-blog-list li .date {
  font-style: italic;
  padding-left: 4px;
  font-size: 14px;
}

.related-blog-list li:last-child {
  margin-bottom: 0;
}

.create-post-main {
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 0 10px #ddd;
  padding: 40px;
  position: relative;
  margin-bottom: 40px;
}

.create-post-main h3 {
  font-size: 16px;
  color: #222;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}

.create-post-main fieldset {
  margin-bottom: 30px;
}

.create-post-main label {
  display: block;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
}

.create-post-main input[type=text] {
  display: block;
  width: 100%;
  padding: 5px 10px;
}

.create-post-main select {
  display: block;
  width: 100%;
  height: 40px;
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 5px 10px;
}

.create-post-main select option {
  zpadding: 5px 10px;
}

.create-post-main .half {
  width: calc(50% - 30px);
  float: left;
  padding: 0 15px;
}

.create-post-main .radio-item {
  display: inline-block;
  line-height: 38px;
  margin-right: 50px;
}

.create-post-main input[type=radio] {
  margin-right: 5px;
  line-height: 38px;
  vertical-align: text-top;
}

.name-used-1 {
  width: 200px;
  float: left;
  margin-right: 20px;
  height: 38px;
  line-height: 38px;
}

.name-used-2 {
  width: calc(100% - 220px);
  float: left;
  margin-right: 0;
  height: 38px;
  line-height: 38px;
}

.name-used-3 {
  width: 100%;
  float: left;
  margin-top: 20px;
  height: 38px;
  line-height: 38px;
}

.name-used-2 input[type=text],
.name-used-3 select {
  display: inline-block;
  width: calc(100% - 170px);
  padding: 5px 10px;
  height: 38px;
}

.name-used-2 span,
.name-used-3 span {
  display: inline-block;
  margin-right: 20px;
  max-width: 150px;
}

.form-btn {
  padding: 40px 0 20px 0;
}

.form-btn button {
  display: block;
  border: none;
  color: #FFF;
  cursor: pointer;
  font-size: 16px;
  background-color: #1eab85;
  height: 42px;
  line-height: 42px;
  width: 150px;
  font-weight: 300;
  border-radius: 0;
  text-align: center;
  float: left;
  margin-right: 50px;
}

.create-post-main input::placeholder {
  /* Firefox 18- */
  color: #aaa;
}

.create-post-main input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aaa;
}

.create-post-main input::-moz-placeholder {
  /* Firefox 19+ */
  color: #aaa;
}

.create-post-main input:-ms-input-placeholder {
  /* IE 10+ */
  color: #aaa;
}

.create-post-main input:-moz-placeholder {
  /* Firefox 18- */
  color: #aaa;
}


.my-favourites-top {
  padding: 10px 0;
}

.my-favourites-top li {
  float: left;
  border-right: 1px solid #666;
  padding: 0 0px;
  font-size: 15px;
  line-height: 15px;
  color: #253c8c;
}

.my-favourites-top .bookmark-date,
.my-favourites-top .delete {
  width: 20%;
  padding: 0 20px;
  text-align: center;
}

.my-favourites-top .title {
  width: 60%;
  padding: 0 40px;
}

.my-favourites-top .delete {
  border-right: none;
}

.my-favourites-top .title span {
  display: block;
  padding: 20px 0 20px 36px;
  background: url(images/icon-title.png) left center no-repeat;
}

.my-favourites-top .bookmark-date span {
  display: inline-block;
  padding: 20px 0 20px 40px;
  background: url(images/icon-bookmark-date.png) 8px center no-repeat;
}

.my-favourites-top .delete a {
  display: inline-block;
  padding: 20px 0 20px 40px;
  background: url(images/icon-delete.png) 12px center no-repeat;
  background-size: 20px auto;
}

.my-favourites-list {
  background-color: #FFF;
  padding: 0 10px;
}

.my-favourites-list .row {
  margin: 0;
  border-bottom: 1px solid #ccc;
}

.my-favourites-list .title {
  width: 60%;
  height: 80px;
  display: table;
  padding: 0 30px;
}

.my-favourites-list .title p {
  display: table-cell;
  vertical-align: middle;
  font-size: 15px;
  line-height: 18px;
  color: #159872;
  font-weight: 500;
}

.my-favourites-list .title p span {
  font-style: italic;
}

.my-favourites-list .bookmark-date {
  width: 20%;
  height: 80px;
  display: table;
  padding: 0 10px;
}

.my-favourites-list .bookmark-date p {
  display: table-cell;
  vertical-align: middle;
}

.my-favourites-list .bookmark-date p span {
  display: block;
  font-size: 15px;
  line-height: 37px;
  width: 130px;
  height: 38px;
  color: #fa6900;
  font-weight: 400;
  font-style: italic;
  border: 1px solid #fa6900;
  border-radius: 20px;
  margin: 0 auto;
  text-align: center;
}

.my-favourites-list .delete {
  width: 20%;
  height: 80px;
  display: table;
  padding: 0 10px;
}

.my-favourites-list .delete fieldset {
  margin: 32px auto 0;
}

.my-favourites-list .delete input[type=checkbox] {
  display: block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 auto;
}

.my-favourites-list .row:last-child {
  border: 0;
  display: block;
}

.my-favourites-list .btn-apply {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 120px;
  text-align: center;
  font-weight: 300;
  border: none;
  border-radius: 20px;
  color: #FFF;
  margin: 25px 45px 25px 0;
  float: right;
}

.my-favourites-list .delete label {
  display: block !important;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 auto;
}

.location-path {
  margin-bottom: 10px;
}

.location-path li {
  font-size: 13px;
  line-height: 20px;
  float: left;
  margin-right: 4px;
  color: #555;
}

.page-administration {
  padding-bottom: 20px;
}

.page-administration h1 {
  font-size: 25px;
  padding-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: url(images/header-line.png) bottom left no-repeat;
  margin-bottom: 20px;
}

.full-width-header .search-bar {
  color: #FFF;
  /* width: calc(100% - 180px); */
  width: calc(100% - 300px);
  float: left;
}

.full-width-header .search-bar label {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  line-height: 64px;
  margin-right: 20px;
  margin-bottom: 0;
}

.btn-advance-search {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 170px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 15px 0 0;
  float: right;
}

/* 20200610 */
.sort-bar-advance {
  display: block;
  font-size: 13px;
  line-height: 24px;
  font-weight: 300;
  color: #FFF;
  width: 290px;
  /* margin: 15px 0 0; */
  float: right;
}

.sort-bar-advance .ant-select-selection--single {
  border-radius: 30px;

}

.full-width-header .sort-bar-advance label {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  line-height: 64px;
  margin-right: 20px;
  margin-bottom: 0;
}

.full-width-header .search-bar input {
  width: calc(100% - 90px);
  max-width: 500px;
  padding: 0 20px;
  border-radius: 30px;
  line-height: 24px;
  background: #FFF;
  border: 0;
  box-shadow: none;
  font-size: 14px;
  text-overflow: ellipsis;
}

.full-width-header .search-bar input::placeholder {
  color: #aaa;
}

.full-width-header .search-bar input::-moz-placeholder {
  color: #aaa;
}

.full-width-header .search-bar input:-ms-input-placeholder {
  color: #aaa;
}

.full-width-header .search-bar input:-moz-placeholder {
  color: #aaa;
}

.top-line {
  border-top: 4px solid #33b198;
}

.sort-bar {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.sort-bar ul li {
  float: left;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 300;
}

.sort-bar ul li a {
  color: #007eff;
}

.sort-bar .document-name {
  display: block;
  padding-left: 20px;
  background: url(images/icon-sort01.png) left center no-repeat;
}

.sort-bar .last-update {
  display: block;
  padding-left: 25px;
  background: url(images/icon-sort02.png) left center no-repeat;
}

.document-list {
  margin: 0;
}

.document-box {
  width: 100%;
  margin: 0 0 30px 0;
  padding: 20px;
  background-color: #FFF;
  box-shadow: 0 0 10px #ddd;
  border-radius: 5px;
  position: relative;
}

.document-box .left {
  width: 140px;
  height: 110px;
  float: left;
  background-color: #4376cb;
}

.document-box .left img {
  display: block;
  vertical-align: middle;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.document-box .left .document {
  width: 58px;
  height: 73px;
  margin-top: 18px;
}

.document-box .right {
  width: calc(100% - 170px);
  padding: 0;
  height: 100%;
  float: right;
  background-color: #FFF;
}

.document-box .top {
  margin-bottom: 10px;
}

.document-box h3 {
  font-weight: 300;
  color: #333;
  margin-bottom: 0;
  line-height: 22px;
  width: calc(100% - 100px);
  float: left;
}

.document-box .title {
  font-size: 20px;
  line-height: 22px;
  margin-right: 15px;
}

.document-box .date {
  font-size: 15px;
  line-height: 15px;
}

.document-list .action-btn {
  max-width: 100px;
  float: right;
}

.document-box .action-btn a {
  display: inline-block;
}

.document-box .icon-popup-share {
  margin-right: 8px;
}

.document-box p {
  font-size: 15px;
  line-height: 26px;
  color: #333;
  margin-bottom: 30px;
}

.document-box .bottom {
  margin-bottom: 10px;
}

.document-box .bottom p {
  font-size: 15px;
  color: #999;
  margin-bottom: 0;
}

.document-box .update-date {
  float: left;
}

.document-box .info {
  float: right;
}

.admin-popup-header {
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
  font-weight: 400;
}

.admin-popup-header h2 {
  color: #FFF;
  font-size: 14px;
  line-height: 18px;
  padding: 16px 20px;
  width: 100%;
  font-weight: 300;
  margin-bottom: 0;
}

.page-admin-popup {
  background-color: #FFF;
  padding: 20px;
}

.page-admin-popup h3 {
  font-size: 14px;
  font-weight: 300;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.page-admin-popup fieldset {
  margin-bottom: 20px;
}

.popup-form-name label,
.popup-form-checklist label {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.popup-form-name input[type=text] {
  display: block;
  width: calc(100% - 160px);
  padding: 6px 10px;
  float: left;
  max-width: 380px;
  box-shadow: none;
}

.popup-form-name input::placeholder {
  font-size: 14px;
}

.popup-form-name input::-moz-placeholder {
  font-size: 14px;
}

.popup-form-name input:-ms-input-placeholder {
  font-size: 14px;
}

.popup-form-name input:-moz-placeholder {
  font-size: 14px;
}

.popup-form-name button {
  display: block;
  float: right;
  background-color: #f89a0b;
  background-image: linear-gradient(to bottom, #f89a0b, #d87101);
  font-size: 13px;
  line-height: 17px;
  padding: 10px 0;
  width: 100px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin-right: 40px;
  border: none;
}

.popup-form-checklist {
  background-color: #ececec;
  padding: 20px;
}

.popup-form-checklist .checklist-list {
  margin-bottom: 20px;
}

.popup-form-checklist .checkbox-item {
  display: inline-block;
  line-height: 20px;
  margin-right: 20px;
  margin-bottom: 6px;
}

.popup-form-checklist .checkbox-item span {
  display: inline-block;
  width: 80px;
  padding-left: 6px;
  font-size: 14px;
}

.popup-form-checklist .checkall {
  display: block;
  width: 100px;
  margin-right: 0;
}

.popup-form .scrollbar-inner {
  max-height: 400px;
}

.page-admin-popup .description {
  padding: 15px;
  box-shadow: 0 0 10px #eee;
  margin-bottom: 25px;
}

.page-admin-popup .description p {
  margin-bottom: 10px;
  padding-left: 25px;
  font-size: 14px;
}

.page-admin-popup .description .icon-remark {
  background: url(images/icon-remark.png) left center no-repeat;
  color: #e9a43a;
}

.page-admin-popup .description .icon-tick {
  background: url(images/icon-tick.png) left center no-repeat;
  color: #56ae95;
}

.page-admin-popup .description .icon-cross {
  background: url(images/icon-cross.png) left center no-repeat;
  color: #e4654f;
  margin-bottom: 0;
}

.page-admin-popup .result-display {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 30px;
  height: 130px;
}

.page-admin-popup .result-display p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #999;
}

.send-to-following {
  position: relative;
}

.send-to-following .action-btn {
  position: absolute;
  top: -5px;
  right: 0;
}

.send-to-following .action-btn button {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.page-admin-popup .following-list {
  padding: 5px 10px;
  border: 1px solid #ccc;
  margin-bottom: 30px;
  height: 140px;
}

.page-admin-popup .following-item {
  margin-bottom: 5px;
}

.page-admin-popup .following-list span {
  font-size: 14px;
  line-height: 18px;
  color: #071a5d;
}

.page-admin-popup .following-list .green {
  display: block;
  width: 100%;
  color: #56ae95;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.cms-logout-btn {
  border: none;
  color: #FFF !important;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #21265d;
  background-image: linear-gradient(to right, #21245a, #253c8b);
  height: 91px;
  font-weight: 300;
  padding: 0 30px 0 38px;
  border-radius: 0;
}

.cms-logout-btn span {
  background: url(images/icon-logout.png) right center no-repeat;
  padding-right: 25px;
}


.return-to-kms {
  background: url(images/return-to-kms.png) left center no-repeat;
  padding-left: 25px;
  font-size: 14px;
}

.cms-main {
  padding: 0;
  overflow: hidden;
}

.cms-main-default {
  background-color: #f5f8f9;
}

.default-menu {
  background-color: #449868;
  color: white;
}

.menu-toggle-bar {
  width: 100%;
  background: #5a7d86;
  display: none;
}

.btn-menu-toggle {
  color: #FFF;
  padding: 15px 10px 15px 40px;
  display: block;
  width: 100%;
  background: url(images/menu-icon.png) 10px center no-repeat;
}

.cms-content {
  padding: 40px 50px;
  min-height: 100vh;
}

.default-cms-content{
    background: #f0f4f5;
}
.cms-content h1 {
  font-size: 16px;
  color: #222;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}

.cms-main #wrapper {
  /*background: #449868;*/
}

.cms-white-box {
  width: 100%;
  background-color: #FFF;
  padding: 40px;
  position: relative;
  margin-bottom: 40px;
  min-width: 350px;
}

.cms-white-box h2 {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  margin-bottom: 20px;
}

.cms-content form {
  max-width: 700px;
}

.cms-content fieldset {
  margin-bottom: 10px;
}

.form-create-document td {
  padding: 8px 2px;
  vertical-align: middle;
}

.cms-content label {
  font-size: 14px;
  line-height: 18px;
  max-width: 120px;
}

.cms-content input[type=file] {
  font-size: 14px;
}

.cms-content input[type=text] {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 10px;
  border: 1px solid #ccc;
}

.cms-content input[type="radio"],
.cms-content input[type="checkbox"] {
  margin-right: 10px;
  vertical-align: middle;
}

.cms-content .checkbox-label,
.cms-content .radio-label {
  display: inline-block;
  width: 100px;
  margin-right: 20px;
  font-size: 14px;
}

.cms-content input[type=date] {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 10px;
  border: 1px solid #ccc;
}

.cms-table {
  width: 100%;
  margin-bottom: 40px;
}

.form-create-document .left {
  max-width: 180px;
}

.form-create-document .right {}

.form-create-document input[type=text] {
  width: 100%;
  max-width: 380px;
}

.form-create-document input[type=date] {
  width: calc(100% - 160px);
  max-width: 300px;
}


.exp-date-label {
  padding-right: 10px;
  padding-left: 40px;
}

.access-btn {
  float: right;
}

.access-btn button {
  background: #ebebeb;
  border: 1px solid #999;
  padding: 2px 35px;
  border-radius: 0;
  box-shadow: none;
  display: inline-block;
  margin-left: 20px;
}

.list-of-access {
  padding: 15px 0;
  width: 100%;
  max-width: 700px;
  font-size: 14px;
  height: 180px;
  margin-bottom: 30px;
}

.list-of-access optgroup {
  padding: 0 10px;
}

.list-of-access option {
  padding: 5px 0;
}

.list-of-access option::before {
  display: none;
}

.list-of-access:focus {
  outline: 0;
}

.cms-content .access-right .checkbox-label {
  width: 160px;
  margin-right: 0;
}

.form-create-document .special-user-input {
  max-width: unset !important;
  margin-bottom: 20px;
}

.cms-content .form-btn button {
  font-size: 14px;
}



.first-news {
  position: relative;
  height: 100%;
  min-height: 200px;
  box-shadow: 0 0 10px #ccc;
}

.first-news .first-news-photo {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.first-news .date {
  position: absolute;
  display: inline-block;
  padding: 5px 20px;
  top: 0;
  left: 0;
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0.9);
}

.first-news .black-layer {
  position: absolute;
  padding: 10px 20px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

.first-news .black-layer a {
  display: block;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
  color: #FFF;
}

.latest-news-small {
  margin: -10px;
}

.latest-news-box {
  box-shadow: 0 0 10px #ccc;
  width: calc(50% - 20px);
  background: #FFF;
  position: relative;
  float: left;
  margin: 10px;
  height: 156px;
}

.latest-news-thumb {
  float: left;
  width: 206px;
  display: block;
}

.latest-news-thumb img {
  width: 100%;
}


.latest-news-word {
  float: right;
  width: calc(100% - 206px);
  height: 100%;
  padding: 10px 20px 30px 10px;
}

.latest-news-word-only {
  height: 156px;
  padding: 10px;
}

.latest-news-word .title {
  display: block;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  color: white;
}

.latest-news-box .date {
  font-size: 15px;
  line-height: 20px;
  font-weight: 300;
  text-align: right;
  margin: 0;
  color: #999;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.latest-news-word-only .title {
  display: block;
  font-size: 15px;
  line-height: 20px;
  margin: 0 0 5px 0;
  color: #333;
}

.latest-news-word-only .artical {
  display: block;
  font-size: 15px;
  line-height: 18px;
  padding: 0 0 30px 0;
  font-weight: 300;
  color: #333;
}

.latest-news-top {
  margin-bottom: 40px;
}

.other-news h2 {
  border-bottom: 4px solid #777;
  margin-bottom: 20px;
}

.other-news h2 span {
  display: inline-block;
  padding: 15px 40px;
  color: #FFF;
  font-size: 22px;
  background-color: #21265d;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
  font-weight: 400;
}

.other-news-list {
  margin: 0 -10px;
}

.other-news-box {
  box-shadow: 0 0 10px #ccc;
  width: calc(50% - 20px);
  background: #FFF;
  position: relative;
  float: left;
  margin: 10px;
  height: 245px;
}

.other-news-thumb {
  float: left;
  width: 320px;
  display: block;
}

.other-news-thumb img {
  width: 100%;
}

.other-news-word {
  float: right;
  width: calc(100% - 320px);
  height: 100%;
  padding: 15px 15px 30px 15px;
}

.other-news-box .title {
  display: block;
  font-size: 15px;
  line-height: 20px;
  color: #333;
  padding-bottom: 10px;
  margin-bottom: 8px;
  background: url(images/other-news-bg.png) bottom left no-repeat;
}

.other-news-box .artical {
  display: block;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
  color: #333;
}

.other-news-box .date {
  font-size: 15px;
  line-height: 20px;
  font-weight: 300;
  text-align: right;
  margin: 0;
  color: #999;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.other-news-word-only {
  padding: 15px;
}

.other-news-list {
  margin-bottom: 20px;
}

.bottom-news-list {
  margin: 0 -10px;
}

.bottom-news-item {
  width: calc(25% - 20px);
  position: relative;
  float: left;
  margin: 0 10px 30px 10px;
}

.bottom-news-item .title {
  display: table;
  width: 100%;
  height: 50px;
  font-size: 15px;
  line-height: 18px;
  color: #333;
  padding-bottom: 10px;
  margin-bottom: 8px;
  background: url(images/other-news-bg.png) bottom left no-repeat;
}

.bottom-news-item .title a {
  display: table-cell;
  vertical-align: bottom;
}

.bottom-news-item .date {
  font-size: 15px;
  line-height: 20px;
  font-weight: 300;
  margin: 0;
  color: #999;
}

.news-header {
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
  height: 64px;
  font-weight: 400;
}

.news-header h2 {
  color: #FFF;
  padding-left: 45px;
  font-size: 22px;
  line-height: 64px;
  width: calc(100% - 160px);
  float: left;
  font-weight: 300;
  margin-bottom: 0;
  background: url(images/mini-blog.png) left center no-repeat;
}

.news-year {
  width: 160px;
  float: right;
  position: relative;
}

.news-year-collapse {
  -ms-flex-preferred-size: 100%;
  position: absolute;
  flex-basis: 100%;
  left: 0;
  width: 100%;
  top: 52px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #183c6e;
  z-index: 9;
  color: #FFF;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 300;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.next-article {
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.next-article-thumb {
  float: left;
  width: 135px;
  display: block;
}

.next-article-thumb img {
  width: 100%;
}

.next-article-word {
  float: right;
  width: calc(100% - 155px);
}

.next-article-word p {
  font-size: 15px;
  color: #071a5d;
  margin-bottom: 10px;
  padding-top: 20px;
}

.next-article-word .title {
  font-size: 20px;
  line-height: 24px;
  color: #071a5d;
  margin: 0;
  font-weight: 600;
}

.related-article-list {
  background-color: #FFF;
  padding: 15px 20px 15px 30px;
}

.related-article-list li {
  color: #467a62;
  list-style-type: disc;
  margin-bottom: 5px;
}

.related-article-list a {
  color: #467a62;
  font-size: 15px;
  display: block;
}




.knowledge-cocktail-top {
  padding: 10px 0;
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
}

.knowledge-cocktail-top li {
  float: left;
  padding: 0 0px;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  color: #FFF;
}

.knowledge-cocktail-top .topic {
  width: 45%;
  padding: 15px 60px;
}

.knowledge-cocktail-top .poster-by,
.knowledge-cocktail-top .laster-reply {
  width: 20%;
  padding: 15px 10px;
}

.knowledge-cocktail-top .comments {
  width: 15%;
  padding: 15px 10px;
}



.knowledge-cocktail-list {
  background: #FFF;
}

.knowledge-row {
  margin: 0;
}

.knowledge-row .container {
  position: relative;
}

.knowledge-cocktail-list .topic {
  width: 45%;
  height: 80px;
  display: table;
  padding: 0 30px;
  float: left;
}

.knowledge-cocktail-list .poster-by,
.knowledge-cocktail-list .laster-reply {
  width: 20%;
  height: 80px;
  display: table;
  padding: 0 10px;
  float: left;
}

.knowledge-cocktail-list .comments {
  width: 15%;
  height: 80px;
  display: table;
  padding: 0 10px;
  float: left;
}

.knowledge-cocktail-list .topic a {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  line-height: 18px;
  color: #333;
}

.knowledge-cocktail-list .topic a span {
  display: block;
  font-size: 14px;
  font-style: italic;
}

.knowledge-cocktail-list .poster-by p span {
  display: none;
}

.knowledge-cocktail-list .poster-by p,
.knowledge-cocktail-list .laster-reply p,
.knowledge-cocktail-list .comments p {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  line-height: 18px;
  color: #333;
}

.knowledge-cocktail-list .comments p span {
  padding-right: 24px;
  background: url(images/icon-comment.png) right center no-repeat;
}

.knowledge-cocktail-list .topic.icon-type-1 {
  padding-left: 60px;
  background: url(images/icon-knowledge-1.png) left center no-repeat;
}

.knowledge-cocktail-list .topic.icon-type-2 {
  padding-left: 60px;
  background: url(images/icon-knowledge-2.png) left center no-repeat;
}

.knowledge-cocktail-list .topic.icon-type-3 {
  padding-left: 60px;
  background: url(images/icon-knowledge-3.png) left center no-repeat;
}

.knowledge-cocktail-list .knowledge-row:nth-child(odd) {
  background: #FFF;
}

.knowledge-cocktail-list .knowledge-row:nth-child(even) {
  background: #f5fdff;
}

.media-n-resources {
  padding: 40px 0;
  background-color: #FFF;
  background-image: linear-gradient(to bottom, #e9ffef, #FFF);
}

.media-n-resources .container {
  padding-right: 30px;
  padding-left: 30px;
}

.media-resouce-box {
  border: 4px solid #071a5d;
  width: 100%;
  max-width: 240px;
  padding-top: 10px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #FFF;
  box-shadow: 0 0 10px #ccc;
}

.media-resouce-box a {
  display: block;
}

.media-resouce-box a img {
  width: 100%;
}

.media-resouce-box span {
  line-height: 14px;
  padding: 2px 5px 5px 2px;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #071a5d;
  color: #FFF;
  margin-bottom: 0;
}

.search-results h1 {
  font-size: 25px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.search-results .total-results {
  font-size: 14px;
  padding-bottom: 25px;
  background: url(images/header-line-blue.png) left bottom no-repeat;
  margin-bottom: 30px;
}

.search-results .green {
  color: #248a76;
}

.search-results-list li {
  margin-bottom: 50px;
}

.search-results-list h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.search-results-list p {
  font-size: 15px;
  margin-bottom: 0;
}

li.some-align-left-class-name>.ant-dropdown-menu {
  left: auto;
  right: 100%;
}

/************Add by Jenny 8 Jan 2020***********/
.knowledge-top {
  padding: 30px 0 30px 0;
}

.knowledge-top .row {
  margin: 0;
}

.knowledge-header {
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
  height: 64px;
  font-weight: 400;
}

.knowledge-header .header-icon {
  position: absolute;
  left: 20px;
  top: -8px;
}

.knowledge-header h2 {
  color: #FFF;
  padding-left: 45px;
  font-size: 22px;
  line-height: 64px;
  width: calc(100% - 120px);
  float: left;
  font-weight: 300;
  margin-bottom: 0;
  background: url(./images/icon-knowledge.png) left center no-repeat;
}

.knowledge-hot-topic {
  width: 100%;
  background-color: #21265d;
  background-image: linear-gradient(to right, #21245a, #253c8b);
}

.img-hot-topic {
  background: url(./images/hot-topic.png) bottom center no-repeat;
  height: 100%;
  min-height: 335px;
}

.img-hot-topic h2 {
  color: #FFF;
  text-align: center;
  padding-top: 230px;
}

.hot-topic-item {
  background: #FFF;
  box-shadow: 0 0 4px #ccc;
  border-top: 5px solid #467bc5;
  margin-bottom: 20px;
  padding: 20px 30px;
}

.hot-topic-item a {
  display: block;
  width: calc(100% - 120px);
  float: left;
  margin-bottom: 0;
}

.hot-topic-item span {
  display: block;
  width: 100px;
  float: left;
  text-align: right;
}

.knowledge-category-list.clearfix {
  margin: 0 -10px;
}

.knowledge-category {
  box-shadow: 0 0 4px #ccc;
  margin-bottom: 30px;
  height: 366px;
  background: #FFF;
  width: calc(33.33% - 20px);
  margin: 0 10px 30px;
  float: left;
}

.knowledge-category h3 {
  color: #FFF;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  font-weight: 300;
  margin-bottom: 0;
  height: 86px;
  display: table;
  width: 100%;
  letter-spacing: 1px;
}

.knowledge-category .h3-color1 {
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
  border-bottom: 5px solid #246c42;
}

.knowledge-category .h3-color2 {
  background-color: #067d97;
  background-image: linear-gradient(to right, #067d97, #059aa8);
  border-bottom: 5px solid #044f5c;
}

.knowledge-category .h3-color3 {
  background-color: #b84069;
  background-image: linear-gradient(to right, #b84069, #c85d58);
  border-bottom: 5px solid #733339;
}

.knowledge-category .h3-color4 {
  background-color: #476ac4;
  background-image: linear-gradient(to right, #476ac4, #3687d1);
  border-bottom: 5px solid #294073;
}

.knowledge-category .h3-color5 {
  background-color: #fd8c24;
  background-image: linear-gradient(to right, #fd8c24, #fca61d);
  border-bottom: 5px solid #915515;
}

.knowledge-category h3 span {
  display: table-cell;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 50px auto;
  padding-right: 60px;
}

.knowledge-category ul {
  background-color: #FFF;
  min-height: 240px;
}

.knowledge-category ul li {
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  height: 70px;
  display: table;
  width: 100%;
  background: url(./images/knowledge-arrow.png) 20px center no-repeat;
}

.knowledge-category ul li .sub-category {
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
  padding-right: 50px;
  background-size: 45px auto;
  background-repeat: no-repeat;
  background-position: right;
}

.knowledge-category .btn-show-all {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 120px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 0;
}

.knowledge-category ul li:last-child {
  border-bottom: 0;
  padding: 17px 20px;
  background: none;
}

.knowledge-left {
  width: 50%;
  float: left;
  height: 200px;
  background: url(images/knowledge-bg.png) right center no-repeat;
  position: relative;
  background-size: cover;
  display: table;
  cursor: pointer;
}

.knowledge-right {
  width: 50%;
  float: left;
  height: 200px;
  position: relative;
  display: table;
}

.knowledge-header-color1 {
  background-color: #1eab85;
  background-image: linear-gradient(to right, #1eab85, #4bbe67);
  cursor: pointer;
}

.knowledge-header-color2 {
  background-color: #067d97;
  background-image: linear-gradient(to right, #067d97, #059aa8);
  cursor: pointer;
}

.knowledge-header-color3 {
  background-color: #b84069;
  background-image: linear-gradient(to right, #b84069, #c85d58);
  cursor: pointer;
}

.knowledge-header-color4 {
  background-color: #476ac4;
  background-image: linear-gradient(to right, #476ac4, #3687d1);
  cursor: pointer;
}

.knowledge-header-color5 {
  background-color: #ef8206;
  background-image: linear-gradient(to right, #ed880b, #ef8206);
  cursor: pointer;
}

.knowledge-left h1,
.knowledge-right h1 {
  color: #FFF;
  font-weight: 400;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 90px;
  font-size: 2em;
}

.knowledge-bg-arrow {
  position: absolute;
  right: -21px;
  top: 80px;
  z-index: 9;
}

.knowledge-category-bg {
  opacity: 0.3;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 100px;
}

.knowledge-sub-category-list {
  border-left: 1px solid #296499;
  border-top: 1px solid #296499;
}

.sub-category-box {
  width: 25%;
  height: 185px;
  background: #FFF;
  border-bottom: 1px solid #296499;
  border-right: 1px solid #296499;
  text-align: center;
  padding: 30px 0;
  float: left;
  position: relative;
}

.sub-category-box .icon {
  display: block;
  margin-bottom: 20px;
}

.sub-category-box .icon img {
  height: 80px;
}

.sub-category-box .name {
  padding: 0 10px;
  line-height: 18px;
  display: block;
}

.knowledge-tab-list {
  border-left: 1px solid #296499;
  border-top: 1px solid #296499;
  margin-bottom: 30px;
}

.knowledge-tab-box {
  width: 25%;
  height: 90px;
  background: #FFF;
  border-bottom: 1px solid #296499;
  border-right: 1px solid #296499;
  text-align: center;
  float: left;
  display: table;
  position: relative;
}

.knowledge-tab-box a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 20px;
  line-height: 18px;
  cursor: pointer;
}

.knowledge-tab-list .active {
  background: #204e8f;
  color: #FFF;
}

.knowledge-post-list {
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 0 10px #ddd;
  padding: 10px 25px 50px 25px;
  position: relative;
  margin-bottom: 20px;
}

.knowledge-post-list .topic {
  width: 45%;
  height: 80px;
  display: table;
  padding: 0 10px;
  float: left;
}

.knowledge-post-list .poster-by,
.knowledge-post-list .laster-reply {
  width: 20%;
  height: 80px;
  display: table;
  padding: 0;
  float: left;
}

.knowledge-post-list .comments {
  width: 15%;
  height: 80px;
  display: table;
  padding: 0 10px;
  float: left;
}

.knowledge-post-list .topic a {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  line-height: 22px;
  color: #333;
  font-weight: 300;
}


.knowledge-post-list .topic p,
.knowledge-post-list .poster-by p,
.knowledge-post-list .laster-reply p,
.knowledge-post-list .comments p {
  display: table-cell;
  vertical-align: middle;
  font-size: 15px;
  line-height: 18px;
  font-weight: 300;
  color: #333;
}

.knowledge-post-list .knowledge-row {
  border-bottom: 1px solid #bbb;
}

.knowledge-post-list .knowledge-row .container {
  padding: 0;
}

.knowledge-post-list .knowledge-header-row {
  border-bottom: 3px solid #15664c;
}

.knowledge-post-list .knowledge-header-row .topic,
.knowledge-post-list .knowledge-header-row .poster-by,
.knowledge-post-list .knowledge-header-row .laster-reply,
.knowledge-post-list .knowledge-header-row .comments {
  height: 60px;
}

.knowledge-post-bg {
  width: 100%;
  box-shadow: 0 0 10px #ddd;
}

.knowledge-post-details {
  width: 100%;
  background-color: #FFF;
  padding: 30px;
  position: relative;
  margin-bottom: 0;
  border-bottom: 1px solid #ccc;
}

.knowledge-post-header {
  border-bottom: 3px solid #15664c;
  position: relative;
  padding-bottom: 15px;
}

.knowledge-post-header .user-icon {
  width: 56px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.knowledge-post-header .knowledge-post-topic {
  width: calc(100% - 180px);
  display: inline-block;
}

.knowledge-post-header .knowledge-post-topic .post-name {
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
  margin-bottom: 5px;
}

.knowledge-post-header .knowledge-post-topic .post-date {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin-bottom: 10px;
}

.knowledge-post-header .btn-post-reply {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 100px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 15px 0 0;
  position: absolute;
  right: 0;
  top: 0;
}

.knowledge-post-content {
  padding: 20px 0;
  font-weight: 300;
}

.knowledge-post-content p {
  margin-bottom: 0;
}

.knowledge-post-reply {
  width: 100%;
  background-color: #FFF;
  padding: 30px;
  position: relative;
  margin-bottom: 0;
  border-top: 8px solid #edf7f0;
  border-bottom: 1px solid #ccc;
}

.knowledge-reply-header {
  position: relative;
  padding-bottom: 15px;
}

.knowledge-reply-header .user-icon {
  width: 56px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.knowledge-reply-header .knowledge-post-topic {
  width: calc(100% - 180px);
  display: inline-block;
}

.knowledge-reply-header .knowledge-post-topic .user-name {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 5px;
  padding-top: 5px;
  text-transform: uppercase;
}

.knowledge-reply-header .knowledge-post-topic .reply-date {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin-bottom: 10px;
}

.knowledge-reply-header .btn-post-reply {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 100px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 5px 0 0;
  position: absolute;
  right: 0;
  top: 0;
}

.knowledge-reply-content {
  color: rgba(0, 0, 0, 0.9);
  font-family: 'Microsoft JhengHei, Segoe UI Emoji';
  padding: 0;
  font-weight: 300;
}

.knowledge-reply-content p {
  margin-bottom: 0;
}

.btn-post-back {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 10px 0;
  width: 140px;
  text-align: center;
  font-weight: 300;
  border-radius: 20px;
  color: #FFF;
  margin: 30px 0 50px 0;
}

.prev-post,
.next-post {
  border: 1px solid #ccc;
  background: #FFF;
  padding: 15px 30px;
}

/* .prev-post{
  border-bottom:0; 
} */

.prev-post p,
.next-post p {
  margin: 0;
  font-weight: 300;
  padding-left: 40px;
}

.prev-post p {
  background: url(./images/pre-post.png) left center no-repeat;
}

.next-post p {
  background: url(./images/next-post.png) left center no-repeat;
}

.prev-post p a,
.next-post p a {
  display: inline-block;
}

.auth-avatar {
  width: 40px !important;
  height: 40px !important;
}

.quiz-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}

.quiz-item:nth-child(odd) {
  background-color: #eaeaea;
}

.quiz-cat {
  cursor: pointer;
  padding: 5px 10px;
}

.quiz-cat.selected {
  background-color: #eaeaea;
}

.btn-run,
.btn-result,
.btn-edit,
.btn-edit-option,
.btn-remove,
.btn-add {
  display: block;
  background-color: #183c6e;
  background-image: linear-gradient(to bottom, #255aa5, #183c6e);
  font-size: 13px;
  line-height: 15px;
  padding: 3px 0;
  width: 120px;
  text-align: center;
  font-weight: 300;
  border-radius: 10px;
  color: #FFF;
  border: none;
  margin-top: 3px;
  margin-left: 5px;
  float: right;
}


.create-post-main label.ans input[type=radio] {
  margin-right: 15px;
  line-height: 38px;
  vertical-align: text-top;
  margin-top: 4px;
}

.quiz-div {
  margin-bottom: 70px;
}

.quiz-submit {
  display: block;
  margin: 0px auto;
}

.score h2 {
  font-size: 14px;
  text-align: left;
  display: block;
  margin: 0px auto;
  width: 400px;
}

.score p {
  width: 400px;
  display: block;
  font-size: 30px;
  margin: 0 auto;
}

.exam-status p {
  font-size: 14px;
  text-align: left;
  display: block;
  margin: 0px auto;
  width: 400px;
}

.duration p {
  font-size: 14px;
  text-align: left;
  display: block;
  margin: 0px auto;
  width: 400px;
}

.scrollable-div {
  overflow-y: scroll;
  height: 215px;
  padding: 10px;
  border: 1px solid #eaeaea;
}

.searchable-div {
  overflow-y: scroll;
  height: 265px;
  padding: 10px;
  border: 1px solid #eaeaea;
}

.searchable-div input {
  padding: unset;

}

.add-question-form {
  padding: 10px;
  border: 1px solid #eaeaea;
}

.mc-ans input {
  border: 1px solid #eaeaea;
  width: 100%;
}

.correct-answer {
  margin: 30px 0;
}

.question-answers {
  margin-top: 30px;
}

.question-add {
  display: flex;
  align-items: center;
  padding: 5px;
}

.scrollable-div .question-add:nth-child(odd) {
  background-color: #eaeaea;
}

.searched-question {
  display: flex;
  align-items: center;
  padding: 5px;
}

.searchable-div .searched-question:nth-child(odd) {
  background-color: #eaeaea;
}

.quiz-manage-h3 {
  margin-top: 60px;
}

.question-text {
  margin: 10px 0;
}

.searchable-div .search-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.searchable-div .search-bar .search {
  display: flex;
  width: 50%
}

.searchable-div .search-bar .category {
  display: block;
  width: 50%
}

@media only screen and (max-width: 1199px) {
  .knowledge-category h3 {
    font-size: 16px;
  }

  .knowledge-category ul li {
    font-size: 15px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .knowledge-category h3 {
    font-size: 15px;
  }

  .knowledge-category {
    width: calc(50% - 20px);
  }

  .knowledge-category ul {
    min-height: 230px;
  }

  .knowledge-category ul li {
    font-size: 16px;
  }

  .knowledge-left {
    height: 180px;
  }

  .knowledge-right {
    height: 180px;
  }

  .knowledge-bg-arrow {
    top: 66px;
  }

  .knowledge-left h1,
  .knowledge-right h1 {
    font-size: 30px;
  }

  .sub-category-box {
    width: 33.33%;
  }

  .knowledge-tab-box {
    width: 33.33%;
  }

  .knowledge-post-list .poster-by,
  .knowledge-post-list .laster-reply {
    display: none;
  }

  .knowledge-post-list .topic {
    width: calc(100% - 100px);
    height: auto;
    display: block;
    padding: 10px 0;
    float: left;
  }

  .knowledge-post-list .comments {
    width: 90px;
    height: auto;
    display: block;
    padding: 10px 0;
    float: right;
  }

  .knowledge-post-list .knowledge-header-row .topic,
  .knowledge-post-list .knowledge-header-row .poster-by,
  .knowledge-post-list .knowledge-header-row .laster-reply,
  .knowledge-post-list .knowledge-header-row .comments {
    height: auto;
  }

  .knowledge-post-list .comments p {
    text-align: right;
    display: block;
    margin-bottom: 0;
  }

  .knowledge-post-header .knowledge-post-topic .post-name {
    font-size: 17px;
    line-height: 22px;
  }

  .knowledge-post-header .knowledge-post-topic .post-date {
    font-size: 14px;
  }

  .knowledge-reply-header .knowledge-post-topic .reply-date {
    font-size: 14px;
  }

  .knowledge-left h1,
  .knowledge-right h1 {
    padding: 0 60px;
    font-size: 1.5em;
  }
}


@media only screen and (max-width: 767px) {
  .knowledge-category {
    width: calc(100% - 20px);
    height: auto;
  }

  .knowledge-top {
    padding: 0 0 20px 0;
  }

  .img-hot-topic {
    margin-bottom: 20px;
  }

  /* .knowledge-category h3 {
    min-height: unset;
  } */
  .knowledge-category ul {
    min-height: unset;
  }

  .sub-category-box {
    width: 50%;
  }

  .knowledge-category-bg {
    width: 100px;
  }

  .knowledge-tab-box {
    width: 50%;
    font-size: 14px;
    height: 70px;
  }

  .prev-post p a,
  .next-post p a {
    display: block;
  }

  .knowledge-left h1,
  .knowledge-right h1 {
    font-size: 20px;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 575px) {

  .page-content .knowledge-left h1,
  .page-content .knowledge-right h1 {
    font-size: 20px;
    text-align: center;
    padding: 0 20px;
  }

  .knowledge-bg-arrow {
    top: 80px;
    width: 10px;
    right: -10px;
  }

  .knowledge-header h2 {
    font-size: 16px;
    width: 100%;
  }

  .knowledge-header .btn-new-post {
    float: none;
    position: absolute;
    right: 10px;
    font-size: 12px;
    width: 90px;
  }

  .knowledge-tab-box {
    height: 50px;
  }

  .knowledge-post-list .knowledge-row {
    padding: 10px;
  }

  .knowledge-post-list .topic a,
  .knowledge-post-list .comments p {
    font-size: 14px;
    line-height: 20px;
  }

  .knowledge-post-list {
    padding: 10px 10px 30px 10px;
  }

  .knowledge-post-details {
    padding: 20px;
  }

  .knowledge-post-reply {
    padding: 20px;
  }

  .btn-post-back {
    margin: 20px 0 30px 0;
  }

  .knowledge-post-header .knowledge-post-topic {
    width: calc(100% - 160px);
  }

  .knowledge-post-header .btn-post-reply {
    width: 80px;
  }

  .sub-category-box .name {
    font-size: 14px;
  }

  .knowledge-category h3 span {
    background-size: 40px auto;
    padding-right: 50px;
  }
}



@media only screen and (max-width: 480px) {
  .knowledge-tab-box {
    width: 100%;
  }

  .knowledge-category h3 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 420px) {
  .mobile-right-top {
    position: absolute;
    right: 20px;
    top: 0;
    padding: 0.5em 0;
  }
}

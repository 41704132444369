/* .react-pdf__Page__canvas {
  width: 100%!important;
  height: auto!important;
  overflow: scroll;
} */

/* .customViewer {

  height: 100%;
  /* width: 100%;
  height: 600px;
  overflow: scroll;
  text-align: center!important; */
/* } */

/* .customCanvas {
  height: auto!important;
  width: 100%!important; */
  /* max-height: 1000px; */
  /* overflow: auto;
  text-align: center!important;
}  */
/* -------------------------------- */
.customViewer {
  -moz-user-select: none;  
  -webkit-user-select: none;  
  -ms-user-select: none;  
  -o-user-select: none;  
  user-select: none;
  height: 600px;
  /* min-width: 70%; */
  /* width: 100% */
}

.customViewer .customDoc {
  display: flex;
  justify-content: center;
}

.customCanvas {
  height: 600px;
  overflow: auto;
}

.customCanvas canvas{
  padding-left: 0;
  padding-right: 0;
  /* margin-left: auto;
  margin-right: auto; */
  display: block;
}

/* -------------------------------- */

.customCanvas .customLoader {
  width: 70%;
  margin: 35% auto;
}

.customDisabledBtn {
  vertical-align: 0;
  margin-right: 8px;
  color: #fff!important;
  opacity: .65;
}
.customPDFBtn {
  vertical-align: 0;
  margin-right: 8px;
  color: #fff!important;
}
.customPrevBtn {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: 48px;
  color: #fa5b35;
  background-color: #f3f3f3;
  border-color: #fa5b35;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}
.customNextBtn {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: 48px;
  color: #fa5b35;
  background-color: #f3f3f3;
  border-color: #333333;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}
.customResetBtn {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: 48px;
  color: #45fa35;
  background-color: #f3f3f3;
  border-color: #45fa35;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}
.customPages {
  display: inline-block;
  width: calc(100% - 2 * 50px);
}
.customWrapper {
  min-height: 40px;
  font-size: 24px;
  margin-bottom: 1em;
  background-color: #343a40!important;
  padding: 0;
  text-align: center!important;
  border-radius: .25rem!important;
}

video::-internal-media-controls-download-button {
  display:none;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 35px); /* Adjust as needed */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.resources-share-rate {
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 15px;
}

.resources-share-rate .left {
  float: left;
  /* width: 150px; */
}

.resources-share-rate .left img {
  height: 1.2em;
  margin-right: 1em;
}

.resources-share-rate .right {
  float: right;
}

.resources-share-rate .right ul {
  float: left;
  line-height: 1rem;
}

.resources-share-rate .right span {
  float: right;
}

.resources-share-rate .right .rate-mobile {
  display: none;
}



@media only screen and (max-width: 767px){
  
  .resources-share-rate {
    min-height: 120px;
  }

  .resources-share-rate .left, .resources-share-rate .right {
    width: 100%;
    padding-bottom: 1rem;
  }

  .resources-share-rate .right {
    float: left;
    margin-left: auto; 
    margin-right: 0;
  }

  .resources-share-rate .right ul {
    float: left;
  }
  
  .resources-share-rate .right span {
    float: right;
  }
}

@media only screen and (max-width: 539px){
  .resources-share-rate .right span {
    display: none;
  }
  .resources-share-rate .right .rate-mobile {
    display: inline-block;
  }
}

@media only screen and (max-width: 480px){
  .customViewer {
    height: 500px;
  }

  .customCanvas {
    height: 500px;
    overflow: auto;
  }
}
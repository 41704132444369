.login-input {
  height: 100vh;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-notice {
  padding: 3em 0.5em 0;
  font-size: 0.9em;
  line-height: 1.2em;
}
